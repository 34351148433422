import { Config } from '../../Config/apiPath';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: Config.API_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().user.token;
      if (token) {
        headers.set('Authorization', `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCourses: builder.query({
      query: (arg) => {
        const page = arg.page;
        return {
          url: `cursuri/cursuri/?page=${page}`,
          method: 'GET',
          params: arg,
        };
      },
    }),
    getSessions: builder.query({
      query: (courseId) => ({
        url: `cursuri/sesiuni/`,
        method: 'GET',
      }),
    }),
    getCourseDetails: builder.query({
      query: (id) => ({
        url: `cursuri/cursuri/${id}/`,
        method: 'GET',
      }),
    }),
    getClients: builder.query({
      query: () => ({
        url: `clienti/client/`,
        method: 'GET',
      }),
    }),
    getInstitutions: builder.query({
      query: () => ({
        url: `users/institutie/`,
        method: 'GET',
      }),
    }),
    enrollCourse: builder.mutation({
      query: (courseId, isInstitution, anc_certificat, bookingOffer) => ({
        url: `date/inscriere-curs/`,
        method: 'POST',
        body: {
          curs: courseId,
          persoana_fizica: isInstitution,
          cu_certificat_anc: anc_certificat,
          bookingOffer: bookingOffer,
        },
      }),
    }),
    createClient: builder.mutation({
      query: (client) => ({
        url: `clienti/client/`,
        method: 'POST',
        body: client,
      }),
    }),
    createUser: builder.mutation({
      query: (user) => ({
        url: `users/create/`,
        method: 'POST',
        body: user,
      }),
    }),
    updateUser: builder.mutation({
      query: (user) => ({
        url: `users/user/`,
        method: 'PATCH',
        body: user,
      }),
    }),
    getUserByEmail: builder.query({
      query: (email) => ({
        url: `users/get_by_email/${email}/`,
        method: 'GET',
      }),
    }),
    updateUserByEmail: builder.mutation({
      query: (email, user) => ({
        url: `users/update_by_email/${email}/`,
        method: 'PATCH',
        body: user,
      }),
    }),
    getHotels: builder.query({
      query: (locationId) => ({
        url: `/locatii/hoteluri/?locatie=${locationId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetCoursesQuery,
  useGetCourseDetailsQuery,
  useEnrollCourseMutation,
  useGetHotelsQuery,
  useGetSessionsQuery,
  useGetClientsQuery,
  useGetInstitutionsQuery,
  useCreateClientMutation,
  useCreateUserMutation,
  useGetUserByEmailQuery,
} = apiSlice;
