import { useState, useEffect } from 'react';
import useInput from '../../Hooks/useInput';
import { useSelector } from 'react-redux';
import { createInstitution } from '../../services/User/UserService';

export const useInstitutionForm = (onSave) => {
  const token = useSelector((state) => state.user.token);

  const isEmail = (value) =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  const isNotEmpty = (value) => value.trim() !== '';

  const [emailHelperText, setEmailHelperText] = useState('');

  let formIsValid = false;

  const submitHandler = async () => {
    const createInstitutionRes = await createInstitution(
      token,
      JSON.stringify({
        nume: institutionNameValue,
        descriere: institutionDescriptionValue,
        cod_fiscal: fiscalCodeValue,
        email: emailValue,
        telefon: phoneValue,
        address: addressValue,
        banca: bankValue,
        iban: ibanValue,
        reprezentant_legal: legalRepresentativeValue,
      }),
    );
    if (createInstitutionRes.ok) {
      onSave(createInstitutionRes.data);
    } else {
      console.log('error', createInstitutionRes.errors);
      alert('Eroare', 'Institutia nu a putut fi adaugata');
    }
  };

  const {
    value: institutionNameValue,
    hasError: institutionNameHasError,
    valueChangeHandler: institutionNameChangeHandler,
    inputBlurHandler: institutionNameBlurHandler,
  } = useInput(isNotEmpty);

  const {
    value: institutionDescriptionValue,
    hasError: institutionDescriptionHasError,
    valueChangeHandler: institutionDescriptionChangeHandler,
    inputBlurHandler: institutionDescriptionBlurHandler,
  } = useInput();

  const {
    value: fiscalCodeValue,
    hasError: fiscalCodeHasError,
    valueChangeHandler: fiscalCodeChangeHandler,
    inputBlurHandler: fiscalCodeBlurHandler,
  } = useInput();

  const {
    value: addressValue,
    hasError: addressHasError,
    valueChangeHandler: addressChangeHandler,
    inputBlurHandler: addressBlurHandler,
  } = useInput();

  const {
    value: emailValue,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
  } = useInput(isEmail);

  const {
    value: phoneValue,
    hasError: phoneHasError,
    valueChangeHandler: phoneChangeHandler,
    inputBlurHandler: phoneBlurHandler,
  } = useInput();

  const {
    value: bankValue,
    hasError: bankHasError,
    valueChangeHandler: bankChangeHandler,
    inputBlurHandler: bankBlurHandler,
  } = useInput();

  const {
    value: ibanValue,
    hasError: ibanHasError,
    valueChangeHandler: ibanChangeHandler,
    inputBlurHandler: ibanBlurHandler,
  } = useInput();

  const {
    value: legalRepresentativeValue,
    hasError: legalRepresentativeHasError,
    valueChangeHandler: legalRepresentativeChangeHandler,
    inputBlurHandler: legalRepresentativeBlurHandler,
  } = useInput(isNotEmpty);

  useEffect(() => {
    setEmailHelperText(emailHasError ? 'Adresa de email nu este valida' : '');
  }, [emailHasError]);

  return {
    emailValue,
    emailChangeHandler,
    emailBlurHandler,
    emailHasError,
    emailHelperText,
    institutionNameValue,
    institutionNameHasError,
    institutionNameChangeHandler,
    institutionNameBlurHandler,
    institutionDescriptionValue,
    institutionDescriptionHasError,
    institutionDescriptionChangeHandler,
    institutionDescriptionBlurHandler,
    addressValue,
    addressHasError,
    addressChangeHandler,
    addressBlurHandler,
    phoneValue,
    phoneHasError,
    phoneChangeHandler,
    phoneBlurHandler,
    ibanValue,
    ibanHasError,
    ibanChangeHandler,
    ibanBlurHandler,
    fiscalCodeValue,
    fiscalCodeHasError,
    fiscalCodeChangeHandler,
    fiscalCodeBlurHandler,
    bankValue,
    bankHasError,
    bankChangeHandler,
    bankBlurHandler,
    legalRepresentativeValue,
    legalRepresentativeHasError,
    legalRepresentativeChangeHandler,
    legalRepresentativeBlurHandler,
    formIsValid,
    submitHandler,
  };
};
