import React from 'react';
import { Button } from '@mui/material';
import Select from 'react-select';

import { useSessionSelect } from './useSessionSelect';

import classes from './SessionSelect.module.css';

const SessionSelect = () => {
  const {
    selectedSession,
    setSelectedSession,
    selectedPackage,
    setSelectedPackage,
    sessions,
    selectedCourse,
    handleButtonClick,
  } = useSessionSelect();

  return (
    <div className={classes.main}>
      <h5 style={{ textAlign: 'center' }}>{selectedCourse?.nume}</h5>
      <div className={classes.selectView}>
        <label>Sesiune Curs</label>
        <Select
          value={selectedSession}
          isSearchable
          onChange={(item) => setSelectedSession(item)}
          options={sessions.map((item) => ({
            ...item,
            label: `${item?.perioada?.data_start} - ${item?.perioada?.data_sfarsit}, ${item?.locatia?.oras}`,
          }))}
        />
      </div>
      <div className={classes.selectView}>
        <label>Pachet</label>
        <Select
          value={selectedPackage}
          onChange={(item) => setSelectedPackage(item)}
          options={[
            {
              id: 1,
              label: 'Contract',
              type: 'contract',
            },
            {
              id: 2,
              label: 'Formare Pachet',
              type: 'formare_pachet',
            },
          ]}
        />
      </div>
      <div className={classes.divider} />
      <Button
        variant="contained"
        disabled={!selectedSession || !selectedPackage}
        style={{ alignSelf: 'center', marginTop: 20 }}
        onClick={handleButtonClick}
      >
        Adauga client
      </Button>
    </div>
  );
};

export default SessionSelect;
