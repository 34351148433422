import React, { useState, useEffect } from 'react';
import useInput from '../../Hooks/useInput';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useCreateUserMutation } from '../../Store/Api/apiSlice';
import {
  getUserByEmail,
  updateUser,
} from '../../services/User/UserService';
import { createCourseEnrolment } from '../../services/Courses/CoursesService';
import { courseActions } from '../../Store/Courses/courseSlice';

export const useUserForm = (goToBooking, clientEmail) => {
  const token = useSelector((state) => state.user.token);
  const courseState = useSelector((state) => state.course);

  const [user, setUser] = useState();
  const [throughSeap, setThroughSeap] = useState(false);

  const [createUser, error] = useCreateUserMutation();

  //console.log('user', user);

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isEmail = (value) =>
    value
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );

  const [emailHelperText, setEmailHelperText] =
    useState('');
  const [cnpHelperText, setCnpHelperText] = useState('');
  const [hasCollegeDegree, setHasCollegeDegree] =
    useState(false);
  const [idFile, setIdFile] = useState();
  const [lastSchoolFile, setLastSchoolFile] = useState();
  const [birthCertificateFile, setBirthCertificateFile] =
    useState();
  const [marriageFile, setMarriageFile] = useState();
  const [wantsBooking, setWantsBooking] = useState(false);
  const [firstNameValue, setFirstNameValue] = useState('');
  const [lastNameValue, setLastNameValue] = useState('');
  const [cnpValue, setCnpValue] = useState('');
  const [birthPlaceValue, setBirthPlaceValue] =
    useState('');
  const [motherNameValue, setMotherNameValue] =
    useState('');
  const [fatherNameValue, setFatherNameValue] =
    useState('');
  const [schoolValue, setSchoolValue] = useState('');
  const [addressValue, setAddressValue] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');

  const onCreateUser = async () => {
    let userData;
    if (user) {
      userData = {
        nume: firstNameValue,
        prenume: lastNameValue,
        cnp: cnpValue,
        //data_nasterii: null,
        observatii_interne: '',
        locul_nasterii: birthPlaceValue,
        prenume_mama: motherNameValue,
        prenume_tata: fatherNameValue,
        studii_superioare:
          user.studii_superioare || hasCollegeDegree,
        absolvent_al: schoolValue,
        adresa: addressValue,
        telefon_mobil: phoneNumber,
      };
      const updatedUserRes = await updateUser(
        token,
        userData,
        emailValue,
      );
      if (!wantsBooking) {
        //alert('User salvat cu succes!');
        await createEnrolment(user.id);
      } else {
        dispatch(
          courseActions.selectCursant({
            cursant: user.id,
          }),
        );
        dispatch(
          courseActions.selectThroughSeap({
            throughSeap: throughSeap,
          }),
        );
        goToBooking();
      }
    } else {
      let createUserInfo = {
        email: emailValue,
        password: '12345678',
        nume: lastNameValue,
        prenume: firstNameValue,
        cnp: cnpValue || null,
        //data_nasterii: null,
        observatii_interne: '',
        locul_nasterii: birthPlaceValue,
        prenume_mama: motherNameValue,
        prenume_tata: fatherNameValue,
        studii_superioare: hasCollegeDegree,
        absolvent_al: schoolValue,
        adresa: addressValue,
        telefon_mobil: phoneNumber,
      };
      const createUserData =
        await createUser(createUserInfo);
      if (createUserData.data && !wantsBooking) {
        await createEnrolment(createUserData.data.id);
      } else if (wantsBooking) {
        dispatch(
          courseActions.selectCursant({
            cursant: createUserData.data.id,
          }),
        );
        dispatch(
          courseActions.selectThroughSeap({
            throughSeap: throughSeap,
          }),
        );
        goToBooking();
      }
    }
  };

  const {
    value: emailValue,
    isValid: emailIsValid,
    hasError: emailHasError,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: resetEmail,
  } = useInput(isEmail, clientEmail || user?.email || '');

  useEffect(() => {
    setEmailHelperText(
      emailHasError ? 'Adresa de email nu este valida' : '',
    );
  }, [emailHasError]);

  // useEffect(() => {
  //   setCnpHelperText(
  //     cnpHasError ? 'CNP-ul nu este valid' : '',
  //   );
  // }, [cnpHasError]);

  // const { getUserByEmail, data: userData } =
  //   useGetUserByEmailQuery(emailValue);

  const handleSearchUser = async () => {
    const userData = await getUserByEmail(
      token,
      clientEmail,
    );
    if (userData && userData.ok) {
      setUser(userData.data);
      setFirstNameValue(userData.data.nume);
      setLastNameValue(userData.data.prenume);
      setCnpValue(userData.data.cnp);
      setBirthPlaceValue(userData.data.locul_nasterii);
      setMotherNameValue(userData.data.prenume_mama);
      setFatherNameValue(userData.data.prenume_tata);
      setSchoolValue(userData.data.absolvent_al);
      setAddressValue(userData.data.adresa);
      setHasCollegeDegree(userData.data.studii_superioare);
      setPhoneNumber(userData.data.telefon_mobil);
      setBirthCertificateFile(
        userData.data.certificat_nastere,
      );
      setMarriageFile(userData.data.certificat_casatorie);
      setIdFile(userData.data.buletin);
      setLastSchoolFile(userData.data.ultima_diploma);
    } else {
      setUser('');
      setFirstNameValue('');
      setLastNameValue('');
      setCnpValue('');
      setBirthPlaceValue('');
      setMotherNameValue('');
      setFatherNameValue('');
      setSchoolValue('');
      setAddressValue('');
      setHasCollegeDegree(false);
    }
  };

  const createEnrolment = async (userId) => {
    const courseEnrolmentRes = await createCourseEnrolment(
      token,
      courseState.course.id,
      courseState.session.id,
      courseState.client.id,
      null,
      throughSeap,
      userId,
      courseState.package?.type || null,
    );
    if (courseEnrolmentRes.ok) {
      alert('Inscriere realizata cu succes!');
      navigate('/cursuri');
    }
    // TODO: Adauga mesaj de eroare ce contine eroarea primita de la server
  };

  useEffect(() => {
    if (clientEmail) {
      handleSearchUser();
    }
  }, [clientEmail]);

  return {
    emailValue,
    emailChangeHandler,
    emailBlurHandler,
    emailHasError,
    emailHelperText,
    firstNameValue,
    setFirstNameValue,
    lastNameValue,
    setLastNameValue,
    cnpValue,
    setCnpValue,
    birthPlaceValue,
    setBirthPlaceValue,
    motherNameValue,
    setMotherNameValue,
    fatherNameValue,
    setFatherNameValue,
    schoolValue,
    setSchoolValue,
    addressValue,
    setAddressValue,
    handleSearchUser,
    hasCollegeDegree,
    setHasCollegeDegree,
    idFile,
    setIdFile,
    marriageFile,
    setMarriageFile,
    lastSchoolFile,
    setLastSchoolFile,
    birthCertificateFile,
    setBirthCertificateFile,
    token,
    wantsBooking,
    setWantsBooking,
    onCreateUser,
    getUserByEmail,
    throughSeap,
    setThroughSeap,
    phoneNumber,
    setPhoneNumber,
  };
};
