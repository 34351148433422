import React from 'react';
import {
  TextField,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import Select from 'react-select';
import { useClientForm } from './useClientForm';
import InstitutionForm from '../InstitutionForm/InstitutionForm';

import classes from './ClientForm.module.css';

const ClientForm = ({ onSave, onCancel }) => {
  const {
    nameValue,
    nameHasError,
    nameChangeHandler,
    nameBlurHandler,
    emailValue,
    emailHasError,
    emailChangeHandler,
    emailBlurHandler,
    budgetInstitutionValue,
    budgetInstitutionChangeHandler,
    budgetInstitutionBlurHandler,
    cifValue,
    cifChangeHandler,
    cifBlurHandler,
    townValue,
    townChangeHandler,
    townBlurHandler,
    countryValue,
    countryChangeHandler,
    countryBlurHandler,
    isPersoanaFizica,
    setIsPersoanaFizica,
    isPlatitorTva,
    setIsPlatitorTva,
    institutions,
    setSelectedInstitution,
    selectedInstitution,
    isInstitutionOpen,
    setIsInstitutionOpen,
    onCreateClient,
    refetch,
  } = useClientForm(onSave);

  return (
    <Box component="form" sx={{ mt: 1 }}>
      <div>
        <div className={classes.formRow}>
          <TextField
            label="Nume"
            required
            value={nameValue}
            onChange={nameChangeHandler}
            onBlur={nameBlurHandler}
            error={nameHasError}
            fullWidth
            helperText={nameHasError ? 'Numele este obligatoriu' : ''}
          />
          <div className={classes.divider} />
          <TextField
            label="Email"
            required
            value={emailValue}
            fullWidth
            onChange={emailChangeHandler}
            onBlur={emailBlurHandler}
            error={emailHasError}
            helperText={emailHasError ? 'Email-ul este invalid' : ''}
          />
          <div className={classes.divider} />
          <TextField
            label="CIF"
            value={cifValue}
            onChange={cifChangeHandler}
            onBlur={cifBlurHandler}
            fullWidth
          />
        </div>
        <div className={classes.formRow}>
          <TextField
            label="Buget institutie"
            value={budgetInstitutionValue}
            onChange={budgetInstitutionChangeHandler}
            onBlur={budgetInstitutionBlurHandler}
            fullWidth
          />
          <div className={classes.divider} />
          <TextField
            label="Oras"
            value={townValue}
            onChange={townChangeHandler}
            onBlur={townBlurHandler}
            fullWidth
          />
          <div className={classes.divider} />
          <TextField
            label="Tara"
            value={countryValue}
            onChange={countryChangeHandler}
            fullWidth
            onBlur={countryBlurHandler}
          />
        </div>

        <FormControlLabel
          control={
            <Checkbox
              checked={isPersoanaFizica}
              onChange={() => setIsPersoanaFizica(!isPersoanaFizica)}
            />
          }
          label="Persoana Fizica"
        />
        {!isPersoanaFizica && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isPlatitorTva}
                onChange={() => setIsPlatitorTva(!isPlatitorTva)}
              />
            }
            label="Platitor TVA"
          />
        )}
        {!isPersoanaFizica && (
          <div className={classes.form}>
            <h5>Institutie</h5>
            {isInstitutionOpen ? (
              <InstitutionForm
                onCancel={() => setIsInstitutionOpen(false)}
                onSave={(institution) => {
                  setIsInstitutionOpen(false);
                  refetch();
                  setSelectedInstitution({
                    ...institution,
                    label: institution.nume,
                  });
                }}
              />
            ) : (
              <div className={classes.row}>
                <div className={classes.selectView}>
                  <Select
                    value={selectedInstitution}
                    isSearchable
                    onChange={(item) => setSelectedInstitution(item)}
                    options={institutions.map((item) => ({
                      ...item,
                      label: item?.nume,
                    }))}
                  />
                </div>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  onClick={() => setIsInstitutionOpen(true)}
                >
                  Adauga Institutie
                </Button>
              </div>
            )}
          </div>
        )}
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onCreateClient}
        >
          Salveaza Client
        </Button>
        <Button
          variant="outlined"
          sx={{ mt: 3, mb: 2 }}
          style={{ marginLeft: 10 }}
          onClick={onCancel}
        >
          Anuleaza
        </Button>
      </div>
    </Box>
  );
};

export default ClientForm;
