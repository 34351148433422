import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { courseActions } from '../../../Store/Courses/courseSlice';
import {
  useGetCourseDetailsQuery,
  useGetClientsQuery,
} from '../../../Store/Api/apiSlice';

export const useSelectClientPage = () => {
  const [course, setCourse] = useState();
  const [withCertificate, setWithCertificate] = useState(false);
  const [legalInstitution, setLegalInstitution] = useState(false);
  const [clients, setClients] = useState([]);
  const [isCreateClientOpen, setIsCreateClientOpen] = useState(false);
  const [isCreateInstOpen, setIsCreateInstOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState();

  const params = useParams();
  const { cursId } = params;

  const { data } = useGetCourseDetailsQuery(cursId);

  const { data: clientsData } = useGetClientsQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      setCourse(data);
      dispatch(courseActions.selectCourse({ course: data }));
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (clientsData) {
      setClients(clientsData);
    }
  }, [clientsData]);

  useEffect(() => {
    if (selectedClient) {
      dispatch(
        courseActions.selectClient({
          client: selectedClient,
        }),
      );
    }
  }, [selectedClient, dispatch]);

  return {
    course,
    cursId,
    clients,
    withCertificate,
    setWithCertificate,
    legalInstitution,
    selectedClient,
    setSelectedClient,
    setLegalInstitution,
    isCreateClientOpen,
    setIsCreateClientOpen,
    isCreateInstOpen,
    setIsCreateInstOpen,
    isLoading: false,
  };
};
