import React from 'react';
import { useSelectClientPage } from './useSelectClientPage';
import { Row, Container } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Loading from '../../../Components/Loading/Loading';
import ClientForm from '../../../Components/ClientForm/ClientForm';

import classes from './SelectClientPage.module.css';

const SelectClientPage = ({ route, navigation }) => {
  const {
    course,
    cursId,
    clients,
    isLoading,
    selectedClient,
    isCreateClientOpen,
    setIsCreateClientOpen,
    setSelectedClient,
  } = useSelectClientPage();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container className={classes.main}>
      <h4
        style={{
          textAlign: 'center',
          marginBottom: '1rem',
        }}
      >
        {course?.nume}
      </h4>
      <div className={classes.form}>
        <h5>Client </h5>
        {isCreateClientOpen ? (
          <ClientForm
            onCancel={() => setIsCreateClientOpen(false)}
            onSave={(client) => {
              setSelectedClient({ ...client, label: client.nume });
              setIsCreateClientOpen(false);
            }}
          />
        ) : (
          <div className={classes.row}>
            <div className={classes.selectView}>
              <Select
                value={selectedClient}
                isSearchable
                onChange={(item) => setSelectedClient(item)}
                options={clients.map((item) => ({
                  ...item,
                  label: item?.nume,
                }))}
              />
            </div>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={() => setIsCreateClientOpen(true)}
            >
              Adauga client
            </Button>
          </div>
        )}
      </div>
      <Row
        //md={2}
        style={{
          alignSelf: 'center',
          marginTop: '2rem',
          marginBottom: '2rem',
        }}
      >
        <Link
          style={{ textDecoration: 'none' }}
          to={`/cursuri/${cursId}/inscriere`}
        >
          <Button
            variant="contained"
            style={{
              alignSelf: 'center',
            }}
            disabled={!selectedClient}
          >
            Salveaza si Adauga Cursant
          </Button>
        </Link>
      </Row>
    </Container>
  );
};

export default SelectClientPage;
