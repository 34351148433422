import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useGetSessionsQuery } from '../../../Store/Api/apiSlice';
import { courseActions } from '../../../Store/Courses/courseSlice';

export const useSessionSelect = () => {
  const [selectedPackage, setSelectedPackage] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [sessions, setSessions] = useState([]);

  const params = useParams();
  const { cursId } = params;
  const selectedCourse = useSelector((state) => state.course.course);
  const navigation = useNavigate();

  const { data: courseSessions } = useGetSessionsQuery(
    selectedCourse?.id || cursId,
  );

  const dispatch = useDispatch();

  const handleButtonClick = () => {
    navigation(`/cursuri/${cursId}/sesiuni/${selectedSession.id}`, {
      cursId: cursId,
      sesiuneId: selectedSession.id,
    });
    dispatch(
      courseActions.selectSession({
        session: selectedSession,
      }),
    );
    dispatch(
      courseActions.selectPackage({
        package: selectedPackage,
      }),
    );
  };

  useEffect(() => {
    if (courseSessions) {
      setSessions(courseSessions);
    }
  }, [courseSessions]);

  return {
    selectedPackage,
    setSelectedPackage,
    selectedSession,
    setSelectedSession,
    sessions,
    selectedCourse,
    handleButtonClick,
  };
};
