import { Config } from '../../Config/apiPath';

const createUser = async (email, password, name, firstname) => {
  try {
    const response = await fetch(`${Config.API_BASE_URL}/users/create/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        nume: name,
        prenume: firstname,
      }),
    });
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    } else {
      return { ok: false, errors: response.status };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const login = async (email, password) => {
  try {
    const response = await fetch(`${Config.API_BASE_URL}/users/token/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    });
    const res = await response.json();
    if (response.ok) {
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: 'Error login' };
  }
};

const updateUser = async (token, user, email) => {
  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/users/update_by_email/${email}/`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token ' + token,
        },
        body: JSON.stringify(user),
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const uploadUserDocument = async (token, file, label, email) => {
  let formData = new FormData();
  formData.append(label, file, file.name);

  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/users/update_by_email/${email}/`,
      {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          Authorization: 'Token ' + token,
        },
        body: formData,
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const getUserByEmail = async (token, email) => {
  try {
    const response = await fetch(
      `${Config.API_BASE_URL}/users/get_by_email/${email}/`,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Token ' + token,
        },
      },
    );
    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    }
  } catch (err) {
    return { ok: false, errors: err.message };
  }
};

const createInstitution = async (token, institution) => {
  try {
    const response = await fetch(`${Config.API_BASE_URL}/users/institutie/`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Token ' + token,
      },
      body: institution,
    });

    if (response.ok) {
      const res = await response.json();
      return { ok: true, data: res };
    } else {
      return { ok: false, errors: response.status };
    }
  } catch (err) {
    console.log('err', err.message);
    return { ok: false, errors: err.message };
  }
};

export {
  createUser,
  login,
  updateUser,
  getUserByEmail,
  uploadUserDocument,
  createInstitution,
};
