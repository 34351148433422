import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import AllCoursesPage from '../Pages/Courses/AllCourses/AllCoursesPage';
import Header from '../Components/Header/Header';
import SelectClientPage from '../Pages/Courses/SelectClient/SelectClientPage';
import EnrollCourse from '../Pages/Courses/CourseEnrollment/EnrollCourse';
import SessionSelect from '../Pages/Courses/SessionSelect/SessionSelect';
import MyAccount from '../Pages/MyAccount/MyAccount';
import Cazare from '../Pages/OfertaCazare/Cazare';

const AppRouter = () => {
  // const location = window.location
  // console.log('location', location)
  // let resolved = useResolvedPath('/')
  // let match = useMatch({ path: resolved.pathname, end: true})
  // console.log('mathc', match)
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Navigate to="/cursuri" replace />} />
        <Route path="/cursuri" element={<AllCoursesPage />} />
        <Route path="/contul-meu" element={<MyAccount />} />
        <Route
          path="/cursuri/:cursId/sesiuni/:sesiuneId"
          element={<SelectClientPage />}
        />
        <Route path="/cursuri/:cursId/sesiuni/" element={<SessionSelect />} />
        <Route path="/cursuri/:cursId/inscriere" element={<EnrollCourse />} />
        <Route path="/cursuri/:cursId/cazare" element={<Cazare />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
